import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
export class DietProgramOrdersService {
  constructor(
    private readonly fireStore: AngularFirestore,
    private readonly fireAuth: AngularFireAuth
  ) {}

  /**
   * Updates order as paid.
   * @param id Id of the order
   */
  public createIsPaid(id: string) {
    return this.fireStore.collection('iap_orders').doc(id).update({
      is_paid: true,
      mark_date: firebase.firestore.Timestamp.now().toMillis(),
      mark_user: this.fireAuth.auth.currentUser.uid,
    });
  }
}
