export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  ipURL: 'https://www.askipo.com/api/ip',
  cloudFunctionUrl: 'https://us-central1-askhippo-development.cloudfunctions.net/',
  sqlApi: 'https://maradona.askipo.com/api.development/'
  // sqlApi: 'https://askipo.com/api/'
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAptyqUzFdzuhsO7WQWXUQfbqQDr2Xm50o',
  authDomain: 'askhippo-development.firebaseapp.com',
  databaseURL: 'https://askhippo-development.firebaseio.com',
  projectId: 'askhippo-development',
  storageBucket: 'askhippo-development.appspot.com',
  messagingSenderId: '914822170849',
  appId: '1:914822170849:web:698dbf7804636986da8fa0'
};

export const apiURL = 'https://us-central1-askhippo-development.cloudfunctions.net/';

export const statusesURL = 'https://maradona.askipo.com/api.development/outbound/statuses';

export const tagURL = 'https://maradona.askipo.com/api.development/outbound/tags'
