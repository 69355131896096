import {Injectable} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: AngularFireStorage
  ) {
  }

  public async uploadMultipleFiles(files: any[], path: string, uid: string) {
    const result = Array.from(files).map(async (file, i) => {
      const ref = this.storage.ref('documents/' + uid + path + i + file.name);
      await ref.put(file);
      return await ref.getDownloadURL().toPromise();
    });

    return await Promise.all(result);
  }

  public async uploadAvatar(url: string, uid: string) {
    const imgRef = this.storage.ref(`users/${uid}.png`);
    const snapshot = await imgRef.putString(url, 'data_url');

    const result = await snapshot.ref.getDownloadURL();
    return result;
  }

  public async uploadAvatarForManager(url: string, uid: string) {
    const imgRef = this.storage.ref(`managers/${uid}.png`);
    const snapshot = await imgRef.putString(url, 'data_url');

    const result = await snapshot.ref.getDownloadURL();
    return result;
  }

  public async uploadVideo(url: string, uid: string) {
    const vidRef = this.storage.ref(`users/${uid}.mp4`);
    const snapshot = await vidRef.putString(url, 'data_url');

    const result = await snapshot.ref.getDownloadURL();
    return result;
  }
}
