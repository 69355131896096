import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PatientService } from './patient.service';
import { DoctorService } from '@core/services/doctor.service';
import { Observable } from 'rxjs';
import { Menu, Day } from './../models/menu.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { UserPackage } from '@core/models/user_packages.model';
import { User } from '@core/auth/_models/user.model';
import * as firebase from 'firebase/app';
import { ViewFoods } from '@core/models/view_foods.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  ApiUrl = environment.sqlApi;
  constructor(
    private afs: AngularFirestore,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private httpClient: HttpClient
  ) {}
  public async updateMenu(days: Day[], menuID: string) {
    const menu = {
      ip_address: await this.doctorService.ipAddress,
      modified_date: firebase.firestore.Timestamp.now().toMillis(),
      day_count: days.length,
    };
    const batchArray = [this.afs.firestore.batch()];
    let batchIndex = 0;
    let operationCounter = 0;
    const menuRef = this.afs.collection('menus').doc(menuID);
    const daysRef = menuRef.collection('days').ref;
    await menuRef.update({ ...menu });
    const oldDays = await menuRef.collection('days').ref.get();
    oldDays.forEach((day) => {
      batchArray[batchIndex].delete(daysRef.doc(day.id));
      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    days.forEach((day) => {
      const id = day.id || this.afs.createId();
      delete day.id;
      batchArray[batchIndex].set(daysRef.doc(id), {
        ...day,
      });
      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    batchArray.forEach(async (batch) => await batch.commit());
    return true;
  }

  public copyMenu(menu: Menu) {
    const batchArray = [this.afs.firestore.batch()];
    let batchIndex = 0;
    let operationCounter = 0;
    this.afs
      .collection('menus')
      .add({
        name: menu.name + ' - Kopya',
        doctor_uid: menu.doctor_uid,
        modified_date: new Date().getTime(),
        ip_address: menu.ip_address,
        day_count: menu.day_count,
      })
      .then((cloneMenu) => {
        this.afs
          .collection('menus')
          .doc(menu.id)
          .collection('days')
          .ref.get()
          .then((data) => {
            console.log(data.docs);
            const dayRef = this.afs
              .collection('menus')
              .doc(cloneMenu.id)
              .collection('days').ref;
            data.forEach((day) => {
              batchArray[batchIndex].set(dayRef.doc(), {
                ...day.data(),
              });
              operationCounter++;
              if (operationCounter === 499) {
                batchArray.push(this.afs.firestore.batch());
                batchIndex++;
                operationCounter = 0;
              }
            });
            batchArray.forEach(async (batch) => await batch.commit());
            return true;
          });
      });
  }

  public getMenus(): Observable<Menu[]> {
    const uid = this.doctorService.getUID();
    return this.afs
      .collection('menus', (query) =>
        query.where('doctor_uid', '==', uid).orderBy('modified_date', 'desc')
      )
      .valueChanges({ idField: 'id' }) as Observable<Menu[]>;
  }

  public getMenuDays(menuID: string) {
    return this.afs
      .collection('menus')
      .doc(menuID)
      .collection('days')
      .ref.orderBy('date', 'asc')
      .get();
  }

  public getMenu(menuID: string): Observable<Menu> {
    return this.afs
      .collection('menus')
      .doc(menuID)
      .valueChanges() as Observable<Menu>;
  }
  public getMenuPromise(menuID: string) {
    return this.afs.collection('menus').doc(menuID).ref.get();
  }

  public getClients() {
    const uid = this.doctorService.getUID();
    return this.afs
      .collection('user_packages', (query) =>
        query.where('author_uid', '==', uid)
      )
      .valueChanges({ idField: 'id' }) as Observable<UserPackage[]>;
  }

  public getClientsPromise() {
    const uid = this.doctorService.getUID();
    return this.afs
      .collection('user_packages', (query) =>
        query.where('author_uid', '==', uid)
      )
      .ref.get();
  }
  public getAssignedClients() {
    const uid = this.doctorService.getUID();
    const today = firebase.firestore.Timestamp.now()
      .toDate()
      .setHours(0, 0, 0, 0);
    return this.afs
      .collection('user_packages')
      .ref.where('author_uid', '==', uid)
      .where('assigned_menu_last_date', '>', today).get();
  }
  public getUnassignedClients() {
    const uid = this.doctorService.getUID();
    const today = firebase.firestore.Timestamp.now()
      .toDate()
      .setHours(0, 0, 0, 0);
    return this.afs
      .collection('user_packages')
      .ref.where('author_uid', '==', uid)
      .where('assigned_menu_last_date', '<=', today).get();
  }
  public getClientPackageEndDate(clientID: string) {
    return this.afs
      .collection('user_packages')
      .ref.where('assigned_user.uid', '==', clientID)
      .where('author_uid', '==', this.doctorService.getUID())
      .limit(1)
      .get()
      .then((data) => {
        const client = data.docs[0].data() as UserPackage;
        const activePackage = client.packages[client.packages.length - 1];
        const packageStartDate = new Date(activePackage.purchase_date);
        return packageStartDate.setMonth(
          packageStartDate.getMonth() + activePackage.months.length
        );
      });
  }
  public AssingMenuToClient(
    menuID: string,
    client: UserPackage,
    startDate: number
  ) {
    const batchArray = [this.afs.firestore.batch()];
    let batchIndex = 0;
    let operationCounter = 0;
    const activePackage = client.packages[client.packages.length - 1];
    const packageStartDate = new Date(activePackage.purchase_date);
    const packageEndDate = packageStartDate.setMonth(
      packageStartDate.getMonth() + activePackage.months.length
    );
    return this.afs
      .collection('menus')
      .doc(menuID)
      .ref.get()
      .then((menu) => {
        const clients = (menu.get('clients') || []) as string[];
        if (clients.indexOf(client.assigned_user.uid) === -1) {
          clients.push(client.assigned_user.uid);
          batchArray[batchIndex].update(
            this.afs.collection('menus').doc(menuID).ref,
            {
              clients,
            }
          );
          operationCounter++;
        }
        const assignedMenuLastDate =
          startDate + 86400000 * (menu.get('day_count') - 1) > packageEndDate
            ? packageEndDate
            : startDate + 86400000 * (menu.get('day_count') - 1);
        batchArray[batchIndex].update(
          this.afs.collection('user_packages').doc(client.id).ref,
          {
            assigned_menu_last_date: assignedMenuLastDate,
          }
        );
        operationCounter++;
        return this.getMenuDays(menuID).then((days) => {
          let dayIndex = 0;
          days.forEach((day) => {
            const newDay = {
              ...day.data(),
            };
            newDay.date = startDate + 86400000 * dayIndex;
            delete newDay.id;
            if (newDay.date <= assignedMenuLastDate) {
              const clientRef = this.afs
                .collection('users')
                .doc(client.assigned_user.uid)
                .collection('days').ref;
              batchArray[batchIndex].set(clientRef.doc(), {
                ...newDay,
                menuID,
              });
              operationCounter++;
              if (operationCounter === 499) {
                batchArray.push(this.afs.firestore.batch());
                batchIndex++;
                operationCounter = 0;
              }
            }
            dayIndex++;
          });
          batchArray.forEach(async (batch) => await batch.commit());
          return true;
        });
      });
  }
  public updateMenuClients(
    menuID: string,
    clients: string[],
    packages: UserPackage[]
  ) {
    const batchArray = [this.afs.firestore.batch()];
    let batchIndex = 0;
    let operationCounter = 0;
    return this.afs
      .collection('menus')
      .doc(menuID)
      .update({ clients })
      .then(() => {
        return this.getMenuDays(menuID).then((days) => {
          packages.forEach((p) => {
            const date = firebase.firestore.Timestamp.now()
              .toDate()
              .setHours(0, 0, 0, 0);
            let dayIndex = 1;
            let lastAssignedDate = p.assigned_menu_last_date || 0;
            days.forEach((day) => {
              const newDay = {
                ...day.data(),
              };
              newDay.date = date + 86400000 * dayIndex;
              if (p.assigned_menu_last_date > date) {
                newDay.date = p.assigned_menu_last_date + 86400000 * dayIndex;
              }
              delete newDay.id;
              const lastPackage = p.packages.slice(-1)[0];
              const purchaseDate = new Date(p.create_date);
              const lastDayOfPackage = purchaseDate.setMonth(
                purchaseDate.getMonth() + lastPackage.months.length
              );
              if (newDay.date <= lastDayOfPackage) {
                lastAssignedDate = newDay.date;
                const clientRef = this.afs
                  .collection('users')
                  .doc(p.assigned_user.uid)
                  .collection('days').ref;
                batchArray[batchIndex].set(clientRef.doc(), {
                  ...newDay,
                  menuID,
                });
                operationCounter++;
                if (operationCounter === 499) {
                  batchArray.push(this.afs.firestore.batch());
                  batchIndex++;
                  operationCounter = 0;
                }
              }
              dayIndex++;
            });
            batchArray[batchIndex].update(
              this.afs.collection('user_packages').doc(p.id).ref,
              {
                assigned_menu_last_date: lastAssignedDate,
              }
            );
            operationCounter++;
            if (operationCounter === 499) {
              batchArray.push(this.afs.firestore.batch());
              batchIndex++;
              operationCounter = 0;
            }
          });
          batchArray.forEach(async (batch) => await batch.commit());
          return true;
        });
      });
  }

  public getMenuClient(uid: string) {
    return this.afs.collection('users').doc(uid).valueChanges() as Observable<
      User
    >;
  }

  public getClientMenus(uid: string) {
    return this.afs
      .collection('menus', (query) =>
        query.where('clients', 'array-contains', uid)
      )
      .valueChanges({ idField: 'id' }) as Observable<Menu[]>;
  }

  public getClientDays(uid: string) {
    return this.afs
      .collection('users')
      .doc(uid)
      .collection('days')
      .ref.orderBy('date')
      .get();
  }

  public getEmptyWeek(index: number): Day[] {
    const emptyArray: Day[] = [];
    for (let i = index * 7; i < (index + 1) * 7; i++) {
      emptyArray.push({
        date: i,
        breakfast: {
          foods: [],
        },
        afterBreakfast: {
          foods: [],
        },
        afternoon: {
          foods: [],
        },
        beforeEvening: {
          foods: []
        },
        evening: {
          foods: [],
        },
      });
    }
    return emptyArray;
  }

  public getEmptyWeekWithDate(date: number): Day[] {
    const emptyArray: Day[] = [];
    for (let i = 0; i < 7; i++) {
      emptyArray.push({
        date,
        breakfast: {
          foods: [],
        },
        afterBreakfast: {
          foods: [],
        },
        afternoon: {
          foods: [],
        },
        beforeEvening: {
          foods: []
        },
        evening: {
          foods: [],
        },
      });
      date = date + 86400000;
    }
    return emptyArray;
  }

  public getPatientDays(date: number) {
    const uid = this.patientService.getUID();
    return this.afs
      .collection('users')
      .doc(uid)
      .collection('days')
      .ref.where('date', '>=', date)
      .orderBy('date', 'asc')
      .limit(7)
      .get();
  }

  public updatePatientDay(day: Day) {
    const uid = this.patientService.getUID();
    return this.afs
      .collection('users')
      .doc(uid)
      .collection('days')
      .doc(day.id)
      .update(day);
  }

  public updatePatientDays(uid: string, days: Day[]) {
    const batchArray = [this.afs.firestore.batch()];
    const doctorUID = this.doctorService.getUID();
    let batchIndex = 0;
    let operationCounter = 0;
    const lastDayDate = days.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      } else {
        return 1;
      }
    })[0].date;
    const daysRef = this.afs.collection('users').doc(uid).collection('days');
    days.forEach((day) => {
      batchArray[batchIndex].update(daysRef.doc(day.id).ref, {
        ...day,
      });
      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    batchArray.forEach(async (batch) => await batch.commit());
    return this.afs
      .collection('user_packages')
      .ref.where('assigned_user.uid', '==', uid)
      .where('author_uid', '==', doctorUID)
      .get()
      .then((packages) => {
        return this.afs
          .collection('user_packages')
          .doc(packages.docs[0].id)
          .update({ assigned_menu_last_date: lastDayDate });
      });
  }

  public getFoods(q: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8;',
    });
    return this.httpClient
      .get(`${this.ApiUrl}foods/${q}`, { headers })
      .toPromise() as Promise<ViewFoods[]>;
  }
  public getDoctorFoods(q: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8;',
    });
    const uid = this.doctorService.getUID();
    return this.httpClient
      .get(`${this.ApiUrl}foods/${q}/${uid}`, { headers })
      .toPromise() as Promise<ViewFoods[]>;
  }
  public getDoctorAllFoods(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8;',
    });
    const uid = this.doctorService.getUID();
    return this.httpClient
      .get(`${this.ApiUrl}foods/DoctorFoods/${uid}`, { headers })
      .toPromise() as Promise<ViewFoods[]>;
  }
  public async addFood(model: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8;',
    });
    const ipAddress = await this.doctorService.ipAddress;
    return this.httpClient
      .post(
        `${this.ApiUrl}foods/`,
        {
          DoctorUID: this.doctorService.getUID(),
          Name: model.food,
          Portion: model.portion,
          Description: model.description,
          IpAddress: ipAddress,
        },
        { headers }
      )
      .toPromise();
  }

  public async updateFood(model: ViewFoods) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8;',
    });
    const ipAddress = await this.doctorService.ipAddress;
    return this.httpClient
      .put(
        `${this.ApiUrl}foods/${model.id}`,
        {
          DoctorUID: this.doctorService.getUID(),
          Name: model.name,
          Portion: model.portion,
          Description: model.description,
          IpAddress: ipAddress,
        },
        { headers }
      )
      .toPromise();
  }


  public deleteFood(id: number) {
    const uid = this.doctorService.getUID();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .delete(`${this.ApiUrl}foods/${id}/${uid}`, {
        headers,
      })
      .toPromise();
  }

  public updateMenuDay(menuID: string, day: Day) {
    const dayID = day.id ? day.id : this.afs.createId();
    return this.afs
      .collection('menus')
      .doc(menuID)
      .update({ modified_date: firebase.firestore.Timestamp.now().toMillis() })
      .then(() => {
        return this.afs
          .collection('menus')
          .doc(menuID)
          .collection('days')
          .doc(dayID)
          .set(day);
      });
  }

  public cloneWeek(menuID: string, days: Day[], dayCount: number) {
    return this.afs
      .collection('menus')
      .doc(menuID)
      .update({
        day_count: dayCount,
        modified_date: firebase.firestore.Timestamp.now().toMillis(),
      })
      .then(() => {
        days.forEach((day) => {
          delete day.id;
          return this.afs
            .collection('menus')
            .doc(menuID)
            .collection('days')
            .add(day);
        });
      });
  }
  public async createMenu(name: string) {
    const menu: Menu = {
      ip_address: await this.doctorService.ipAddress,
      modified_date: firebase.firestore.Timestamp.now().toMillis(),
      day_count: 7,
      name,
      doctor_uid: this.doctorService.getUID(),
    };
    return this.afs
      .collection('menus')
      .add(menu)
      .then((res) => {
        const days = this.getEmptyWeek(0);
        days.forEach((day) => {
          this.afs.collection('menus').doc(res.id).collection('days').add(day);
        });
        return res.id;
      });
  }
  public async editMenuName(menuID: string, name: string) {
    return this.afs
      .collection('menus')
      .doc(menuID)
      .update({
        name,
        modified_date: firebase.firestore.Timestamp.now().toMillis(),
      });
  }
  public changeClientDay(clientID: string, day: Day) {
    return this.afs
      .collection('users')
      .doc(clientID)
      .collection('days')
      .doc(day.id)
      .update(day);
  }
  public addClientDays(clientID: string, days: Day[]) {
    days.forEach((day) => {
      delete day.id;
      this.afs.collection('users').doc(clientID).collection('days').add(day);
    });
    return this.afs
      .collection('user_packages')
      .ref.where('assigned_user.uid', '==', clientID)
      .where('author_uid', '==', this.doctorService.getUID())
      .limit(1)
      .get()
      .then((data) => {
        return this.afs
          .collection('user_packages')
          .doc(data.docs[0].id)
          .update({ assigned_menu_last_date: days[days.length - 1].date });
      });
  }

  public deleteClientDays(clientID: string, days: Day[], deletedDays: Day[]) {
    const batchArray = [this.afs.firestore.batch()];
    let batchIndex = 0;
    let operationCounter = 0;
    const clientRef = this.afs
      .collection('users')
      .doc(clientID)
      .collection('days').ref;
    deletedDays.forEach((day) => {
      batchArray[batchIndex].delete(clientRef.doc(day.id));
      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    days.forEach((day) => {
      const newDay = {
        ...day,
      };
      delete newDay.id;
      batchArray[batchIndex].update(clientRef.doc(day.id), {
        ...newDay,
      });
      operationCounter++;
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    batchArray.forEach(async (batch) => await batch.commit());
    return this.afs
      .collection('user_packages')
      .ref.where('assigned_user.uid', '==', clientID)
      .where('author_uid', '==', this.doctorService.getUID())
      .limit(1)
      .get()
      .then((data) => {
        return this.afs
          .collection('user_packages')
          .doc(data.docs[0].id)
          .update({ assigned_menu_last_date: days[days.length - 1].date });
      });
  }
}
