import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreadService } from '@core/services/thread.service';
import { UserStore } from '@core/store/user.store';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'kt-profile-vertical-card',
  templateUrl: './profile-vertical-card.component.html',
  styleUrls: ['./profile-vertical-card.component.scss']
})
export class ProfileVerticalCardComponent implements OnInit {

  @Input() profileImage: string;
  @Input() name: string;
  @Input() date: number;
  @Input() uid: string;
  @Input() image = 'assets/media/logos/askipo.png'
  @Input() useCase: 'client-profile-page' | 'settings-page' | 'doctor-detail' | 'patient-detail' | 'manager-detail';

  @Input() subscriptionType: string;
  @Input() daysLeft: number;

  option = 0;
  @Output() changeOption: EventEmitter<number> = new EventEmitter<number>();

  @Output() preview: EventEmitter<any> = new EventEmitter<any>();

  threadID: string;

  constructor(
    private threadService: ThreadService,
    private store: UserStore,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  onPreview() {
    this.preview.emit();
  }

  change(value) {
    this.option = value;
    this.changeOption.emit(value);
  }

  async chat() {
    if (!this.threadID) {
      const id = this.store.getValue().user.uid;
      const threads = await this.threadService.getThreadsByIds(id, this.uid);

      if (!threads.docs.length) {
        return;
      }

      this.threadID = threads.docs[0].id;

    }
    this.router.navigate(['/chat/' + this.threadID]);
  }
}
