import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Food, NutrionalValue } from '@core/models/food.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FoodService {
  private readonly baseUrl = environment.sqlApi;

  constructor(private readonly http: HttpClient) {}

  /**
   * Gets all the food in the given page.
   */
  public getFoods(pageNumber: number): Promise<Array<Food>> {
    return this.http.get<Array<Food>>(
      `${this.baseUrl}food/list/${pageNumber}`
    ).toPromise();
  }

  /**
   * Gets food by id
   */
  public getFoodById(id: string): Promise<Food> {
    return this.http.get<Food>(
      `${this.baseUrl}food/getfood/${id}`
    ).toPromise();
  }

  /**
   * Creates food with the given document.
   */
  public createFood(food: Food): Promise<Food> {
    return this.http
      .post<Food>(`${this.baseUrl}food/justfood`, food)
      .toPromise();
  }

  /**
   * Updates food with the given document.
   */
  public updateFood(food: Food): Promise<Food> {
    return this.http
      .put<Food>(`${this.baseUrl}food/justfood/${food.id}`, food)
      .toPromise();
  }

  /**
   * Deletes food with the given id.
   */
  public deleteFood(id: number): Promise<any> {
    return this.http
      .delete(`${this.baseUrl}food/foodandportions/${id}`)
      .toPromise();
  }


  /**
   * Creates portion with the given document.
   */
  public createPortion(portion: NutrionalValue): Promise<NutrionalValue> {
    return this.http
      .post<NutrionalValue>(`${this.baseUrl}food/justportion`, portion)
      .toPromise();
  }

  /**
   * Updates the portion with the given document.
   */
  public updatePortion(portion: NutrionalValue) {
    return this.http
      .put<NutrionalValue>(
        `${this.baseUrl}food/justportion/${portion.id}`,
        portion
      )
      .toPromise();
  }

  /**
   * Deletes the portion with the given id.
   */
  public deletePotion(id: number) {
    return this.http.delete(`${this.baseUrl}food/portion/${id}`).toPromise();
  }
}
