import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { ComplaintQuestion } from '@core/models/complaint-question';
import { ComplaintAnswer } from '@core/models/complaint-answer';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  private readonly API_URL = environment.sqlApi;
  constructor(
    private http: HttpClient,
  ) { }

  public getAllComplaintQuestion(pageNum, complaintNum) {
    return this.http.get(this.API_URL + 'forum/questioncomplaint/' + pageNum + '/' + complaintNum)
      .pipe(first()).toPromise() as Promise<ComplaintQuestion[]>;
  }

  public getAllComplaintAnswer(pageNum, complaintNum) {
    return this.http.get(this.API_URL + 'forum/answercomplaint/' + pageNum + '/' + complaintNum)
      .pipe(first()).toPromise() as Promise<ComplaintAnswer[]>;
  }

  public disableQuestion(questionId: number) {
    return this.http.put(this.API_URL + 'forum/disablequestion/'+ questionId,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public disableAnswer(answerId: number) {
    return this.http.put(this.API_URL + 'forum/disableanswer/'+ answerId,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
