import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Support} from "@core/models/support.model";
import {firestore} from "firebase/app";
import {UserStore} from "@core/store/user.store";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private fireStore: AngularFirestore,
    private store: UserStore,
    private http: HttpClient
  ) { }

  public async create(data: Support) {
    const create_date = firestore.Timestamp.now().toMillis();
    const create_uid = this.store.getValue().user.uid;
    const create_ip = await this.ipAddress;

    return this.fireStore.collection('support').add({
      ...data,
      create_uid,
      create_date,
      create_ip,
      status: 'waiting',
      modify_uid: create_uid,
      modify_date: create_date,
      modify_ip: create_ip,
      completed_note: ''
    });
  }

  public async update(data: Support, id: string) {
    const modify_date = firestore.Timestamp.now().toMillis();
    const modify_uid = this.store.getValue().user.uid;
    const modify_ip = await this.ipAddress;

    return this.fireStore.collection('support').doc(id).update({
      ...data,
      modify_uid,
      modify_date,
      modify_ip,
    });
  }

  public async completeTicket(id: string, note: string) {
    const modify_uid = this.store.getValue().user.uid;
    const modify_date = firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('support').doc(id).update({
      complete_note: note,
      modify_ip: await this.ipAddress,
      modify_uid,
      modify_date,
      status: 'completed'
    });
  }

  public getTicket(id: string) {
    return this.fireStore.collection('support').doc(id).ref.get();
  }

  public getTickets(limit: number) {
    return this.fireStore.collection('support', query => query.limit(limit)).valueChanges({ idField: 'id' });
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
