import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NotificationModel, NotificationType, Segment } from '@core/models/notification.model';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly API_URL = environment.sqlApi;
  private noti: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fireStore: AngularFirestore,
    private fireAuth: AngularFireAuth
  ) { }

  public async getNotifications() {
    return this.fireStore.collection('general_notifications')
      .ref
      .where('is_test', '==', false)
      .orderBy('create_date', 'desc')
      .limit(50)
      .get().then((result) => {
        return result.docs.map((item) => {
          return { ...item.data(), id: item.id, };
        });
      });
  }

  public async createNotification(data: any, isTest: boolean) {
    const result = await this.fireStore.collection('general_notifications').add(data);

    const fbUser = await this.fireAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'v2/notification/create-notification', {
      id: result.id,
      uid: fbUser.uid,
      isTest: isTest
    }, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
      })
    }).toPromise();
  }

  public async removeNotification(id: string, onesignalId: string) {
    return this.fireStore.collection('general_notifications').doc(id).update({
      is_deleted: true,
    }).then(async () => {
      const fbUser = await this.fireAuth.user.pipe(
        first()
      ).toPromise();
      const token = await fbUser.getIdToken();

      return this.http.post(environment.cloudFunctionUrl + 'v2/notification/cancel-notification', {
        id: onesignalId,
        uid: fbUser.uid
      }, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
        })
      }).toPromise();
    });
  }

  public async detail(id: string) {
    const fbUser = await this.fireAuth.user.pipe(
      first()
    ).toPromise();
    const token = await fbUser.getIdToken();

    return this.http.post(environment.cloudFunctionUrl + 'v2/notification/notification-detail', {
      id: id,
      uid: fbUser.uid
    }, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
      })
    }).toPromise();
  }

  public getAllNotifications(pageNum, notiNum) {
    return this.http.get(this.API_URL + 'FirebaseGeneralNotificationMessageTemplate/' + pageNum + '/' + notiNum)
      .pipe(first()).toPromise() as Promise<NotificationModel[]>;
  }

  public getNotificationTypes() {
    return this.http.get(this.API_URL + 'FirebaseNotificationType')
      .pipe(first()).toPromise() as Promise<NotificationType[]>;
  }

  public getSegments() {
    return this.http.get(this.API_URL + 'FirebaseNotificationSegment')
      .pipe(first()).toPromise() as Promise<Segment[]>;

  }

  public setNotification(data) {
    return this.noti = data;
  }

  public getNotification() {
    if (!this.noti) {
      this.router.navigate(['/notification-list']);
      alert("Not Found Notification")
    } else {
      return this.noti
    }

  }

  public async addNotification(noti) {
    console.log("noti", noti);

    const add = await this.http.post(this.API_URL + 'FirebaseGeneralNotificationMessageTemplate/', noti)
      .pipe(first()).toPromise();

    return add;
  }

  public async addInstantNotification(noti) {
    const add = await this.http.post(this.API_URL + 'FirebaseGeneralNotificationMessageTemplate/instantnotification', noti)
      .pipe(first()).toPromise();

    return add;
  }

  public deleteNotification(id: string) {
    return this.http.delete(this.API_URL + 'FirebaseGeneralNotificationMessageTemplate/' + id)
      .pipe(first()).toPromise();
  }

  public async updateNotification(noti) {
    let notiTime = new Date(noti.notificationTime).getTime();
    console.log("notiTime", notiTime)

    const update = await this.http.put(this.API_URL + 'FirebaseGeneralNotificationMessageTemplate/' + noti.id + '/' + notiTime, noti)
      .pipe(first()).toPromise();
    await this.router.navigate(["/notification-list"])
    return update;
  }


}
