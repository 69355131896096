import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';

@Injectable()
export class CallCenterService {
  private readonly API_URL = environment.sqlApi + 'outbound/';

  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth
  ) {}

  private async getHttpHeader() {
    const firebaseUser = await this.fireAuth.user.pipe(
      first()
    ).toPromise();
    const token = await firebaseUser.getIdToken();

    return new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
  }

  public async getCustomerList(index: number): Promise<any[]> {
    return this.http.get(this.API_URL + 'firstcontact/' + index + '/' + 100, {
      headers: await this.getHttpHeader(),
    }).toPromise() as any;
  }

  public async getList(index: number, startDate: number, endDate: number, status: string): Promise<any[]> {
    return this.http.get(
      this.API_URL + 'list/' + status + '/' + index + '/100/' + startDate + '/' + endDate,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getPreMeetingList(
    index: number, startDate: number, endDate: number,
    status: string, completed: boolean
  ): Promise<any[]> {
    return this.http.get(
      this.API_URL + 'premeeting/' + status + '/' + completed + '/' +
      index + '/100/' + startDate + '/' + endDate,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getOrderList(
    index: number, startDate: number, endDate: number, status: string
  ): Promise<any[]> {
    return this.http.get(
      this.API_URL + 'order/' + status + '/' +
      index + '/100/' + startDate + '/' + endDate,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getOtherPreMeetingList(
    index: number, startDate: number, endDate: number
  ): Promise<any[]> {
    return this.http.get(
      this.API_URL + 'premeetinguser/' + index + '/100/' + startDate + '/' + endDate,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getStatusList(id: number): Promise<any[]> {
    return this.http.get(
      this.API_URL + 'statuses/' + id,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getOutboundById(outboundId: string) {
    return this.http.get(
      this.API_URL + outboundId, {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getTags() {
    return this.http.get(
      this.API_URL + 'tags',
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }

  public async getReport(startDate: number, endDate: number) {
    return this.http.get(
      this.API_URL + 'report/' + startDate + '/' + endDate,
      {
        headers: await this.getHttpHeader(),
      }
    ).toPromise() as any;
  }
}
