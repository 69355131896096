import {Injectable} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageTemplate } from '@core/models/messageTemplate.model';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {
  constructor( private fireStore: AngularFirestore) {}

  public getAllMessages() {
    return this.fireStore.collection('message_templates').valueChanges();
  }
  public deleteMessage(id:string){
    this.fireStore.collection('message_templates').doc(id).delete();
  }
  public addMessage(data){
    return this.fireStore.collection('message_templates').add({
      title:data.title,
      content:data.content,
    }).then(ref=>ref.update({id:ref.id}));
  }
  public async getMessage(id:string){
    const messageRef = this.fireStore.collection('message_templates').doc(id).ref;
    const messageData = (await messageRef.get()).data()
    return messageData;
  }
  public updateMessage(data:MessageTemplate){
    return this.fireStore.collection('message_templates').doc(data.id).update({
      title:data.title,
      content:data.content
    })
  }
}
