import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { skipWhile, map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserStore } from '@core/store/user.store';
import { User } from '@core/auth/_models/user.model';
import { UserQuery } from '@core/query/user.query';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private router: Router,
    private query: UserQuery
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): any {
    console.log('Role guard');
    return this.query.user$.pipe(
      skipWhile(user => !user),
      map((user) => {
        console.log(user);
        const type = route.data.type; // 'write', 'read'
        const field = route.data.field; // 'doctors', 'reviews' etc

        if (user.permissions[field] && user.permissions[field][type]) {
          return true;
        }

        console.log(user.permissions);
        if (user.permissions.doctors.read) {
          this.router.navigate(['/doctor-list']);
        } else if (user.permissions.managers.read) {
          this.router.navigate(['/manager-list']);
        } else if (user.permissions.patients.read) {
          this.router.navigate(['/patient-list']);
        } else if (user.permissions.reviews.read) {
          this.router.navigate(['/comment-list']);
        } else if (user.permissions.blog.read) {
          this.router.navigate(['/blog-list']);
        } else if (user.permissions.orders.read) {
          this.router.navigate(['/orders-list'])
        } else if (user.permissions.exercise_videos.read) {
          this.router.navigate(['/exercise-videos'])
        } else if (user.permissions.coupons.read) {
          this.router.navigate(['/code-list']);
        } else if (user.permissions.diet_program.read) {
          this.router.navigate(['/diet-program/list']);
        } else if (user.permissions.diet_program.read) {
          this.router.navigate(['/notification']);
        } else if (user.permissions.youtube_video.read) {
          this.router.navigate(['/youtube-video-list']);
        } else {
          this.router.navigate(['/auth/login'])
        }
        return false;
      }), take(1)).toPromise();
  }

}
