import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import { UserStore } from '@core/store/user.store';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ExerciseVideosService {

  constructor(
    private fireStore: AngularFirestore,
    private store: UserStore,
  ) { }

  public async getExerciseVideoDetail(id:string){
    let detailRef = (await this.fireStore.collection('exercise_videos').doc(id).ref.get())
    return {...detailRef.data(), id: detailRef.id}
  }

  public async getExerciseVideos(date: any = 'none', limit: number = 25) {
    let query = this.fireStore.collection('exercise_videos')
      .ref
      .orderBy('create_date', 'desc')

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query
      .limit(limit);

    const snapshot = await query.get();
    return snapshot.docs.map((snap) => {
      return {
        id: snap.id,
        ...snap.data()
      };
    });
  }

  public async createExerciseVideos(data){
    return await this.fireStore.collection('exercise_videos').add({
      ...data,
      enabled: false,
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      create_id: this.store.getValue().user.uid
    });
  }

  public async deleteExerciseVideos(id:string){
    return await this.fireStore.collection('exercise_videos').doc(id).delete();
  }

  public async updateExerciseVideos(id:string, data:any){
    return await this.fireStore.collection('exercise_videos').doc(id).update(data);
  }
}
