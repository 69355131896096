import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageTemplate } from '@core/models/messageTemplate.model';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ForumAnswerMessageTemplateService {

  private collectionName:string = "forum_answer_message_templates";

  constructor(
    private firestore: AngularFirestore
  ) { }

  public async getMessageTemplates(date: number | string = 'none', limit: number = 25) {
    let query = this.firestore.collection(this.collectionName)
      .ref
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(limit);

    return query.get().then((snapshot) => {
      return snapshot.docs.map((snap) => {
        return {
          id: snap.id,
          ...snap.data()
        } as any;
      });
    });
  }

  public async getAllMessageTemplates() {
    let query = this.firestore.collection(this.collectionName)
      .ref
      .orderBy('create_date', 'desc');

    return query.get().then((snapshot) => {
      return snapshot.docs.map((snap) => {
        return {
          id: snap.id,
          ...snap.data()
        } as any;
      });
    });
  }

  public async getMessageTemplate(id: string) {
    return (await this.firestore.collection<MessageTemplate>(this.collectionName).doc(id).ref.get()).data() as MessageTemplate;
  }

  public add(messageTemplate: MessageTemplate) {
    messageTemplate.create_date = firebase.firestore.Timestamp.now().toMillis();
    return this.firestore.collection(this.collectionName).add(messageTemplate);
  }

  public update(id:string, messageTemplate: MessageTemplate) {
    delete messageTemplate.id;
    return this.firestore.collection(this.collectionName).doc(id).update(messageTemplate);
  }

  public delete(id:string) {
    return this.firestore.collection(this.collectionName).doc(id).delete();
  }
}
