import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { WellBeingActivity } from '@core/models/well-being.model';

@Injectable({
  providedIn: 'root'
})
export class WellBeingService {
  private readonly wellBeingActivitiesCollectionName = 'wellbeing_activities';

  constructor(private readonly fireStore: AngularFirestore) {}

  public async getWellBeingActivity(id: string) {
    return this.fireStore.collection(this.wellBeingActivitiesCollectionName)
      .doc(id).get().toPromise().then((snapshot) => {
        return {
          id: snapshot.id,
          ...snapshot.data(),
        } as WellBeingActivity;
      });
  }

  public async getWellBeingActivities(date: number | string = 'none') {
    let query = this.fireStore.collection(this.wellBeingActivitiesCollectionName)
      .ref
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(25);

    return query.get().then((snapshot) => {
      return snapshot.docs.map((snap) => {
        return {
          id: snap.id,
          ...snap.data()
        } as WellBeingActivity;
      });
    });
  }

  public updateWellBeingActivity(id: string, data: Partial<Omit<WellBeingActivity, 'id'>>) {
    return this.fireStore.collection(this.wellBeingActivitiesCollectionName).doc(id).update(data);
  }

  public changeWellBeingActivityStatus(id: string, value: boolean) {
    return this.fireStore.collection(this.wellBeingActivitiesCollectionName)
      .doc(id).update({ enabled: value });
  }
}
