import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(
    private fireStore: AngularFirestore
  ) { }

  getReviews(limit: number) {
    return this.fireStore
      .collection('reviews', query => query.orderBy('date', 'desc').limit(limit))
      .valueChanges({ idField: 'id' });
  }

  getDietProgramReviews(limit: number) {
    return this.fireStore
      .collection('diet_program_reviews', query => query.orderBy('date', 'desc').limit(limit))
      .get()
      .toPromise()
      .then((async (snapshot) => {
          const dietProgramReviews = [];

          for await (const data of snapshot.docs) {
            const [author, program] = await Promise.all([
              this.fireStore.collection('public_users').doc(data.get('author_uid')).get().toPromise(),
              this.fireStore.collection('diet_programs').doc(data.get('program_id')).get().toPromise(),
            ]);


            dietProgramReviews.push({
              id: data.id,
              ...data.data(),
              author: author.data(),
              program: program.data(),
            });
          }

          return dietProgramReviews;
        })
      );
  }

  approve(id: string) {
    return this.fireStore
      .collection('reviews')
      .doc(id).update({ status: true });
  }

  delete(id: string) {
    return this.fireStore
      .collection('reviews')
      .doc(id).delete();
  }
}
