import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Exercise } from '@core/models/exercise.model';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  private readonly API_URL = environment.sqlApi + 'PhysicalActivity';

  constructor(
    private httpClient: HttpClient
  ) { }

  public getAll(page: number = 1, take: number = 20){
    return this.httpClient.get(this.API_URL + `/getAll/${page}/${take}`).pipe(first()).toPromise() as Promise<Exercise[]>;
  }

  public getListCount(){
    return this.httpClient.get(this.API_URL +'/getListCount').pipe(first()).toPromise();
  }

  public async add(exercise: Exercise): Promise<Exercise> {
    return await this.httpClient
      .post<Exercise>(`${this.API_URL}/add`, exercise)
      .toPromise();
  }
}
