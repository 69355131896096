import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ForumQuestionModel } from '@core/models/forum.model';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class ForumService {

  private readonly API_URL = environment.sqlApi;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fireAuth: AngularFireAuth
  ) { }

  private async getHttpHeader() {
    const firebaseUser = await this.fireAuth.user.pipe(
      first()
    ).toPromise();
    const token = await firebaseUser.getIdToken();

    return new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
  }

  public async getAllForums(pageNum, formNum) {
    return this.http.get(this.API_URL + 'forum/latest/' + pageNum + '/' + formNum, {
      headers: await this.getHttpHeader(),
    })
      .pipe(first()).toPromise() as Promise<ForumQuestionModel[]>;
  }

  public async replyQuestion(answer) {
    const post = await this.http.post(this.API_URL + 'forum/PostAnswer', answer, {
      headers: await this.getHttpHeader(),
    }).pipe(first()).toPromise();
    return post
  }

  public disableQuestion(questionId: number) {
    return this.http.put(this.API_URL + 'forum/disablequestion/' + questionId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public async create(forumQuestion: ForumQuestionModel) {
    return this.http
      .post(`${environment.sqlApi}forum/postquestion`, forumQuestion, {
        headers: await this.getHttpHeader(),
      })
      .pipe(first())
      .toPromise() as Promise<ForumQuestionModel>;
  }

}
