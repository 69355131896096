import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import { UserStore } from '@core/store/user.store';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(
    private fireStore: AngularFirestore,
    private store: UserStore,
    private readonly httpClient: HttpClient,
  ) { }

  public async getQuestions(date: any = 'none', limit: number = 25) {
    let query = this.fireStore.collection('questions')
      .ref
      .where('is_archived', '==', false)
      .where('approved', '==', false)
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query
      .limit(limit);

    const snapshot = await query.get();
    return snapshot.docs.map((snap) => {
      return {
        id: snap.id,
        ...snap.data()
      };
    });
  }

  public async deleteQuestions(id:string){
    return await this.fireStore.collection('questions').doc(id).delete();
  }

  public async updateQuestions(id:string, data:any){
    return await this.fireStore.collection('questions').doc(id).update(data);
  }

  /**
   * Returns the archived questions
   */
  public async getArchivedQuestions(
      date: number | string = 'none',
      limit = 25,
  ): Promise<any> {
    let query = this.fireStore.collection('questions')
      .ref
      .where('is_archived', '==', true)
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(limit);

    const snapshot = await query.get();

    return snapshot.docs.map((snap) => {
      return {
        id: snap.id,
        ...snap.data()
      };
    });
  }

  /**
   * Archives the question with the given id
   */
  public archiveQuestion(id: string): Promise<void> {
    return this.fireStore.collection('questions').doc(id)
      .update({ is_archived: true });
  }

  /**
   * Unarchives the question with the given id
   */
  public unarchiveQuestion(id: string): Promise<void> {
    return this.fireStore.collection('questions').doc(id)
      .update({ is_archived: false });
  }

  /**
   * Returns the approved questions
   */
  public async getApprovedQuestions(
      date: number | string = 'none',
      limit = 25,
  ): Promise<any> {
    let query = this.fireStore.collection('questions')
      .ref
      .where('approved', '==', true)
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(limit);

    const snapshot = await query.get();

    return snapshot.docs.map((snap) => {
      return {
        id: snap.id,
        ...snap.data()
      };
    });
  }

  public changeQuestionApproved(question: any, questionId: string): Promise<any> {
    return this.fireStore.collection('questions').doc(questionId)
      .update({ approved: true }).then(() => {
        return this.httpClient
        .post(environment.sqlApi + 'contest/postquestion', {
          Question: {
            Question: question.question,
          },
          Answers: (question.answers as string[]).reduce((prev, curr, idx) => {
            const obj = {
              Answer: curr,
              IsTrue: idx === question.correct_answer_index,
            };

            prev.push(obj);

            return prev;
          }, []),
        })
        .toPromise();
      });
  }
}
