import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Coupon } from '@core/models/coupon.model';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class PromotionCodeService {

  constructor(
    private fireStore: AngularFirestore,
    private fireAuth: AngularFireAuth
  ) { }

  public getAllCodes() {
    return this.fireStore.collection('coupons', (query) =>
      query.orderBy('create_date', 'desc')
        .limit(50)
    ).valueChanges({ idField: 'id' });
  }

  public async getCode(id: string) {
    const codeRef = this.fireStore.collection('coupons').doc(id).ref;
    const codeData = {
      ...(await codeRef.get()).data(),
      id,
    };

    return codeData;
  }

  public async getAbsoluteCode(code: string) {
    const codeRef = await this.fireStore.collection('coupons').ref.where('code', '==', code).get();

    if(codeRef.empty) return false;

    const codeData = {
      ...codeRef.docs[0].data(),
      id: codeRef.docs[0].id 
    };

    return codeData;
  }

  public async getCodes(date: number | string = 'none') {
    let query = this.fireStore.collection('coupons')
      .ref
      .orderBy('create_date', 'desc');

    if (date !== 'none') {
      query = query.startAfter(date);
    }

    query = query.limit(25);

    return query.get().then((snapshot) => {
      return snapshot.docs.map((snap) => {
        return {
          id: snap.id,
          ...snap.data()
        } as Coupon;
      });
    });
  }

  public getPartners() {
    return this.fireStore.collection('partners').valueChanges({ idField: 'id' });
  }

  public activeDoctors() {
    return this.fireStore.collection('packages')
      .ref.where('enabled', '==', true)
      .get().then((result) => {
        return result.docs.map((snap) => {
          return {
            id: snap.id,
            doctorId: snap.get('author.uid'),
            displayName: snap.get('author.firstname') + ' ' + snap.get('author.lastname')
          };
        });
      });
  }

  public createCode(code: string, partner: string, partnerCommission: number, codeType: string, type: string, discount: number, expireDate: number, askipoCommission: boolean, commissionType: string, productType) {
    return this.fireStore.collection('coupons').add({
      code,
      code_type: codeType,
      type,
      discount,
      expire_date: expireDate,
      create_date: firebase.firestore.Timestamp.now().toMillis(),
      enabled: true,
      partner_id: partner,
      partner_commission: partnerCommission,
      askipo_commission: true,
      partner_commission_type: commissionType,
      create_id: this.fireAuth.auth.currentUser.uid,
      product_type: productType
      // doctor_id: doctorId
    });
  }

  public updateCode(id: string, codeType: string, code: string, type: string, discount: number, expireDate: number, partner: string, commissionType: string, partnerCommission: number, productType) {
    return this.fireStore.collection('coupons').doc(id).update({
      code,
      type,
      discount,
      code_type: codeType,
      expire_date: expireDate,
      partner_id: partner,
      partner_commission: partnerCommission,
      partner_commission_type: commissionType,
      product_type: productType
    });
  }

  public changeStatus(id: string, value: boolean) {
    return this.fireStore.collection('coupons').doc(id).update({
      enabled: value
    });
  }
}
